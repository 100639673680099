import React from "react";
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeadingSection";
import { useStaticQuery, graphql } from "gatsby";
import { htmlSerializer } from "../../utils/htmlSerializer";
import Container from "../../components/PageContentContainer";
import { RichText } from "prismic-reactjs";
import { Box, Heading, Text, Link, Stack, Flex } from "@chakra-ui/react";
import Seo from "../../components/SEO";
import Bgimage2 from "../../images/bgimage2.png";
import ContactForm from "../../components/ContactForm";

const Cta = ({ standard }) => {
  return (
    <Box>
      <Box
        maxW="7xl"
        w={{ md: "3xl", lg: "4xl" }}
        mx={{ base: 3, lg: "auto" }}
        my={{ base: 3, lg: 6 }}
        bg={"#2b2f69"}
        boxShadow="xl"
        py={{ base: 3, lg: 6 }}
        px={{ base: 4, lg: 8 }}
        rounded={"md"}
        display={"flex"}
        flexDirection={{ base: "column", lg: "row" }}
        alignItems={"center"}
        justifyContent={{ lg: "space-between" }}
      >
        <Heading as="h3" color={"gray.100"}>
          <Text as="span" display="block" textAlign={"center"}>
            Get {standard} Training from us
          </Text>
        </Heading>
        <Link
          display="block"
          px={5}
          py={3}
          border="solid transparent"
          fontWeight="bold"
          rounded="md"
          shadow="md"
          bg="white"
          textDecoration="none"
          href={"#contact-us"}
          mt={3}
        >
          Apply Now
        </Link>
      </Box>
    </Box>
  );
};

export default function AboutUs() {
  const queryResult = useStaticQuery(graphql`
    query SoftSkillsContent {
      prismicSoftSkills {
        data {
          content {
            richText
          }
        }
      }
    }
  `);

  const richText = queryResult.prismicSoftSkills.data.content.richText;
  return (
    <Layout>
      <Seo title="Soft Skills" />
      <Box
        sx={{
          background: `url(${Bgimage2}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <Container maxW="7xl" py={5}>
          <Box
            d="flex"
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Stack
              align={"center"}
              flex={1}
              py={{ base: 12, md: 16 }}
              direction={{ base: "column", md: "row" }}
            >
              <Flex
                flex={1}
                justify={"center"}
                align={"center"}
                position={"relative"}
                w={"full"}
              >
                <Box flex={1} textAlign={{ base: "center", md: "left" }}>
                  <Heading as="h1" size="2xl" color={"#ffffff"}>
                    Soft Skills
                  </Heading>
                  <Box
                    mx={{ base: "auto", md: "0" }}
                    sx={{
                      marginTop: "1.25rem",
                      marginBottom: "1.25rem",
                      width: "8rem",
                      height: "0.25rem",
                      backgroundColor: "#29a6ff",
                    }}
                  ></Box>
                  <Heading as="h6" size="md" mt={2} color="#ffffff">
                    Virtual Instructor Led Learning <br /> Flexible Timing{" "}
                    <br /> 15+ Years of experience <br /> Certified 12000+
                    Delegates
                  </Heading>
                </Box>
              </Flex>
            </Stack>
            <Box flex={1}>
              <Box
                maxW={"400px"}
                mx={"auto"}
                sx={{
                  "& > *": {
                    width: "100%",
                  },
                }}
              >
                <div id="contact-us"></div>
                <ContactForm />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* <PageHeading title="Soft Skills" /> */}
      <Container maxW={"7xl"} py={5}>
        <RichText render={richText} htmlSerializer={htmlSerializer} />
      </Container>
      <Cta standard={"Soft Skills"} />
    </Layout>
  );
}
